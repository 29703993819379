import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';

// staging site config:
// const firebaseConfig = {
//     apiKey: 'AIzaSyC-CaC7PZymfKV8uzHrxFwLYpGsoIl6qtA',
//     authDomain: 'offshoresms-staging.firebaseapp.com',
//     projectId: 'offshoresms-staging',
//     storageBucket: 'offshoresms-staging.appspot.com',
//     messagingSenderId: '379176628263',
//     appId: '1:379176628263:web:1b2f0beb5269e43c25de02'
// };

// production site config:
const firebaseConfig = {
    apiKey: 'AIzaSyAX5rQ__fBkV2Rd-FvtWB7JPnL7-lMvv4s',
    authDomain: 'app-offshoresms.firebaseapp.com',
    projectId: 'app-offshoresms',
    storageBucket: 'app-offshoresms.appspot.com',
    messagingSenderId: '395258391843',
    appId: '1:395258391843:web:d621d56d614be9f9c8b096',
    measurementId: 'G-30MW3C1M6M'
};
//// REMEMBER TO CHANGE SIGNINFLOW lines 637-640 ish

// Initialize Firebase
// const fb = !firebase.apps.length ? initializeApp(firebaseConfig) : firebase.app()

const fb = initializeApp(firebaseConfig);
const auth = getAuth(fb);
const functions = getFunctions(fb);

// LOCALHOST_DEBUG_TOKEN: 21F826D2-B30C-4670-94FF-33E1D808BDE0
// DEBUG_LOCALHOST: 863f84da-711d-4083-a591-9de1ff33a0df
// STAGING_DEBUG_TOKEN: 3aad0586-5699-433a-bdae-761c533b81fc
// STAGING_DEBUG_TOKEN_2: b40d30bb-9d72-4cb3-9003-6e5e62c47dcb

// self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// initializeAppCheck(app, { /* App Check options */ });
// const appCheck = initializeAppCheck(fb, {
//     provider: new ReCaptchaEnterpriseProvider('6LeMMg4qAAAAAFTPmdGkEnG_H8_B89b8TkBnuzbo'),
//     isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
// });

const db = getFirestore();
// export default db
export { db, functions };

// export default auth
// export const auth;
// const analytics = getAnalytics(app);

// const db = getFirestore()
// export default db
// export const db = firebase.firestore();

// other plugins ==================================================================
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faPhone,
    faCog,
    faUsers,
    faUser,
    faSyncAlt,
    faBook,
    faBriefcase,
    faTools,
    faInfoCircle,
    faQuestion,
    faIdBadge,
    faComment,
    faStopwatch,
    faEdit,
    faShip,
    faListUl,
    faCamera,
    faClipboardList,
    faHome,
    faInfo,
    faLifeRing,
    faCheck,
    faLock,
    faExclamationTriangle,
    faCalendarAlt,
    faFireExtinguisher,
    faScrewdriver,
    faWrench,
    faMoneyBill,
    faPrint,
    faFileSignature,
    faTachometer,
    faTachometerAlt,
    faUserCog,
    faTimes,
    faChevronLeft,
    faSort,
    faChevronRight,
    faArrowsH,
    faMinus,
    faBars,
    faAnchor,
    faPlus,
    faGlobe,
    faTrashAlt,
    faPen,
    faChevronDown,
    faChevronUp,
    faList,
    faScrewdriverWrench,
    faClipboard,
    faPeopleGroup,
    faFolderClosed,
    faBinoculars,
    faEye,
    faListOl,
    faPlayCircle,
    faLightbulb,
    faCarAlt,
    faBuilding,
    faObjectGroup,
    faCheckSquare,
    faTasks,
    faCircleStop,
    faGasPump,
    faCopy,
    faEyeSlash,
    faCircle,
    faTags,
    faFilter,
    faCheckDouble,
    faRefresh,
    faTimeline,
    faCubesStacked,
    faArrowTrendUp,
    faProjectDiagram,
    faAddressCard,
    faCircleUser,
    faSearch,
    faEnvelope,
    faMobileAlt,
    faThumbsUp,
    faClose,
    faExchange,
    faLink,
    faTag,
    faMinusCircle,
    faCheckCircle,
    faListCheck,
    faUserShield,
    faSkullCrossbones,
    faSave,
    faQuestionCircle,
    faStar,
    faTable,
    faMapMarkerAlt,
    faUserSlash,
    faUserCheck,
    faQrcode,
    faCompressAlt,
    faArrowUp,
    faStop,
    faCalendarCheck,
    faRepeat,
    faHashtag,
    faPersonChalkboard,
    faCalendar,
    faClock,
    faUnlock,
    faTrash,
    faTrashCanArrowUp,
    faDownLong,
    faMapLocationDot,
    faKey,
    faPaintBrush,
    faImages,
    faBookOpen,
    faRedoAlt,
    faColumns,
    faClone,
    faIndent,
    faCaretUp,
    faCaretDown,
    faBullseye,
    faArrowRight,
    faBoxArchive,
    faHamburger,
    faOilCan,
    faBell,
    faCloud
} from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
library.add(
    faPhone,
    faCog,
    faUsers,
    faUser,
    faSyncAlt,
    faBook,
    faBriefcase,
    faTools,
    faInfoCircle,
    faQuestion,
    faIdBadge,
    faComment,
    faStopwatch,
    faEdit,
    faShip,
    faListUl,
    faCamera,
    faClipboardList,
    faLifeRing,
    faHome,
    faInfo,
    faCheck,
    faLock,
    faExclamationTriangle,
    faCalendarAlt,
    faFireExtinguisher,
    faScrewdriver,
    faWrench,
    faMoneyBill,
    faPrint,
    faFileSignature,
    faTachometer,
    faTachometerAlt,
    faUserCog,
    faTimes,
    faChevronLeft,
    faSort,
    faChevronRight,
    faArrowsH,
    faMinus,
    faBars,
    faAnchor,
    faPlus,
    faGlobe,
    faTrashAlt,
    faPen,
    faChevronDown,
    faChevronUp,
    faList,
    faShip,
    faScrewdriverWrench,
    faClipboard,
    faPeopleGroup,
    faFolderClosed,
    faBinoculars,
    faEye,
    faListOl,
    faPlayCircle,
    faLightbulb,
    faCarAlt,
    faBuilding,
    faObjectGroup,
    faCheckSquare,
    faTasks,
    faCircleStop,
    faGasPump,
    faCopy,
    faEyeSlash,
    faCircle,
    faTags,
    faFilter,
    faCheckDouble,
    faRefresh,
    faTimeline,
    faCubesStacked,
    faArrowTrendUp,
    faProjectDiagram,
    faAddressCard,
    faCircleUser,
    faSearch,
    faEnvelope,
    faMobileAlt,
    faThumbsUp,
    faSquare,
    faClose,
    faExchange,
    faPhone,
    faLink,
    faTag,
    faMinusCircle,
    faCheckCircle,
    faListCheck,
    faUserShield,
    faSkullCrossbones,
    faSave,
    faQuestionCircle,
    faStar,
    faTable,
    faMapMarkerAlt,
    faUserSlash,
    faUserCheck,
    faQrcode,
    faCompressAlt,
    faArrowUp,
    faStop,
    faCalendarCheck,
    faRepeat,
    faHashtag,
    faPersonChalkboard,
    faCalendar,
    faClock,
    faLock,
    faUnlock,
    faTrash,
    faTrashCanArrowUp,
    faDownLong,
    faMapLocationDot,
    faKey,
    faPaintBrush,
    faImages,
    faBookOpen,
    faRedoAlt,
    faColumns,
    faClone,
    faIndent,
    faCaretUp,
    faCaretDown,
    faBullseye,
    faArrowRight,
    faBoxArchive,
    faHamburger,
    faOilCan,
    faBell,
    faCloud
);

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// import { /* your imports */ } from '@apimatic/core'

import App from './App.vue';
import router from './router';

import VueElementLoading from 'vue-element-loading';

const app = createApp(App);

// const clickOutside = {
//   beforeMount: (el, binding) => {
//     el.clickOutsideEvent = event => {
//       if (!(el == event.target || el.contains(event.target))) {
//         binding.value();
//       }
//     };
//     document.addEventListener("click", el.clickOutsideEvent);
//   },
//   unmounted: el => {
//     document.removeEventListener("click", el.clickOutsideEvent);
//   },
// };
// app.directive("clickOut", clickOutside).mount('.snapshot-sidebar')

import InstantSearch from 'vue-instantsearch/vue3/es';
app.use(InstantSearch);

app.component('VueElementLoading', VueElementLoading);
app.component('font-awesome-icon', FontAwesomeIcon);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.use(router);

app.mount('#app');

// app.directive('highlight', {
//   beforeMount(el, binding, vnode) {
//     el.style.background = binding.value
//   }
// })

import { currentDataStore } from '@/stores/currentdata';
const store = currentDataStore();

// import VueCountryCode from 'vue-country-code';
// app.use(VueCountryCode);

const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            unsubscribe(); // Removes current listener
            resolve(user);
        });
    });
};

router.beforeEach(async (to, from) => {
    /// TODO: need to re-assess the need for this and if it is slowing the page loads down. Also where is page auth being handled?
    if (to.meta.requiresAuth) {
        if (!(await getCurrentUser())) {
            alert("You don't have access. Redirecting to login page");
            return '/authenticate';
        }
    }
});

router.afterEach(() => {
    // console.log('end route');
    // setTimeout(() => {
    //   loader.hide();
    // }, 300);
});

app.directive('click-outside', {
    beforeMount(el, binding) {
        el.clickOutsideEvent = (evt) => {
            evt.stopPropagation();
            if (!(el === evt.target || el.contains(evt.target))) {
                binding.value(evt, el);
                store.setSlidemenu(false);
            }
        };
        window.requestAnimationFrame(() => {
            document.addEventListener('click', el.clickOutsideEvent);
        });
    },
    unmounted(el) {
        document.removeEventListener('click', el.clickOutsideEvent);
    }
});
