<template>
    <div class="tab-content-wrapper" :class="tabClass">
        <slot name="heading"></slot>
        <slot name="tab"></slot>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        isFirst: Boolean,
        isLast: Boolean,
        tabClass: String
    },

    mounted() {}
};
</script>

<style scoped lang="scss">
.tab-content-wrapper {
    background: #f8f8f8;
    // display: flex;
    position: relative;
    padding: 2.8rem 3rem;
    margin: 2rem 1rem;
    border-radius: 12px;
    &:before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        top: -24px;
        // left: 55%;
        border-style: solid;
        border-width: 0 24px 24px 24px;
        border-color: transparent transparent #f8f8f8 transparent;
    }
    &.one {
        &:before {
            // right: 8%;
            right: 110px;
            left: auto;
        }
    }
    &.two {
        &:before {
            // right: 32%;
            right: 390px;
            left: auto;
        }
    }
    &.three {
        &:before {
            // right: 55%;
            right: 680px;
            left: auto;
        }
    }
}

@media (max-width: 959px) {
    .tab-content-wrapper {
        &:before {
            top: -18px;
            border-style: solid;
            border-width: 0 18px 18px 18px;
        }
        &.one {
            &:before {
                right: 65px;
                left: auto;
            }
        }
        &.two {
            &:before {
                right: 232px;
                left: auto;
            }
        }
        &.three {
            &:before {
                right: 360px;
                left: auto;
            }
        }
    }
}
@media (max-width: 550px) {
    .tab-content-wrapper {
        padding: 2rem 1.3rem;
        &:before {
            top: -18px;
            border-style: solid;
            border-width: 0 18px 18px 18px;
        }
        &.one {
            &:before {
                right: 47%;
                left: auto;
            }
        }
        &.two {
            &:before {
                right: 47%;
                left: auto;
            }
        }
        &.three {
            &:before {
                right: 47%;
                left: auto;
            }
        }
    }
}
</style>
