import { defineStore } from 'pinia';
import { getFirestore, doc, getDoc, collection, query, orderBy, onSnapshot, updateDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

import type { User } from '@/types/User.types';
import type { Vessel } from '@/types/Vessel.types';

export const loggedInUserStore = defineStore({
    // id is required so that Pinia can connect the store to the devtools
    id: 'loggedInUser',
    state: () => ({
        userIsLoggedIn: false,
        userData: {} as User,
        userDataIsLoaded: false,
        firebaseUser: {},
        userID: '',
        myFleets: [],
        myFleetsLoaded: false,
        myFaveFleetIDs: [],
        browserIP: '',
        tempUserData: {
            username: '',
            email: '',
            userid: '',
            location: '',
            browserIP: ''
        },
        tempUserPreferences: {},
        myLevelOneVessels: [],
        myLevelTwoVessels: [],
        myLevelThreeVessels: [],
        crewedVesselsLoaded: false,
        myCrewedVessels: [],
        myCrewedVesselIDs: [] as Vessel[],
        mySavedBuilderItems: [],
        mySavedFleetBuilderItems: [],
        isNumzaan: false
    }),
    getters: {
        getFirebaseUser: (state) => state.firebaseUser,
        getUserData: (state) => state.userData,
        getUserDataIsLoaded: (state) => state.userDataIsLoaded,
        getUserID: (state) => state.userID,
        getUserIsLoggedIn: (state) => state.userIsLoggedIn,
        getMyFleets: (state) => state.myFleets,
        getMyFleetsLoaded: (state) => state.myFleetsLoaded,
        getMyFaveFleetIDs: (state) => state.myFaveFleetIDs,
        getBrowserIP: (state) => state.browserIP,
        getTempUserData: (state) => state.tempUserData,
        getCrewedVesselsLoaded: (state) => state.crewedVesselsLoaded,
        getMyCrewedVessels: (state) => state.myCrewedVessels,
        getTempUserPreferences: (state) => state.tempUserPreferences,
        getMySavedBuilderItems: (state) => state.mySavedBuilderItems,
        getMySavedFleetBuilderItems: (state) => state.mySavedFleetBuilderItems,
        getIsNumzaan: (state) => state.isNumzaan
    },
    actions: {
        setFirebaseUser(user: object) {
            this.firebaseUser = user;
        },
        async updateUserData(updateData: object) {
            // console.log('X:', updateData, this.userID);
            const docRef = doc(getFirestore(), 'userdata', this.userID);
            await updateDoc(docRef, updateData);
        },

        checkIfOffshoreAdmin() {
            const validateOffshoreAdmin = httpsCallable(getFunctions(), 'validateOffshoreAdmin');
            validateOffshoreAdmin({ userID: this.userID }).then((result) => {
                let res = result.data.text ?? {};
                this.isNumzaan = res.valid ?? false;
            });
        },

        setUserData() {
            // console.log('loading user data');
            onSnapshot(doc(getFirestore(), 'userdata', this.userID), (doc) => {
                this.userData = doc.data() ?? {};
                this.myLevelOneVessels = this.userData.vesselsThatICrew ?? [];
                this.myLevelTwoVessels = this.userData.vesselsThatImLevelTwo ?? [];
                this.myLevelThreeVessels = this.userData.vesselsThatIMaster ?? [];
                this.myFaveFleetIDs = this.userData.myFaveFleetIDs ?? [];
                this.mySavedBuilderItems = this.userData.mySavedBuilderItems ?? [];
                this.mySavedFleetBuilderItems = this.userData.mySavedFleetBuilderItems ?? [];
                this.userDataIsLoaded = true;
            });
            this.checkIfOffshoreAdmin();
        },
        loadMyFleets() {
            // console.log('my fleets loaded');
            // const q = query(collection(getFirestore(), 'userdata/' + this.userID + '/myCompanies'), orderBy('companyName', 'asc'));
            const q = query(collection(getFirestore(), 'userdata/' + this.userID + '/myFleets'), orderBy('fleetName', 'asc'));
            onSnapshot(q, (querySnapshot) => {
                this.myFleets = [];
                querySnapshot.forEach((doc) => {
                    this.myFleets.push({
                        companyID: doc.id,
                        companyName: doc.data().fleetName,
                        companyLocation: doc.data().fleetLocation ?? 'Location not set',
                        companyState: doc.data().fleetLocation ?? 'Location not set',
                        avatarUrl: doc.data().avatarUrl ?? ''
                    });
                });
            });
            this.myFleetsLoaded = true;
        },
        logOutUser() {
            this.userData = {};
            this.myFleetsLoaded = false;
            this.myFleets = [];
            this.userID = '';
        },
        setUserID(userid: string) {
            this.userID = userid;
        },
        setUserIsLoggedIn(val) {
            this.userIsLoggedIn = val;
        },
        setBrowserIP(ip) {
            this.browserIP = ip;
        },
        setTempUserData(tempUser) {
            this.tempUserData = tempUser;
        },
        async setLocalUserPreferences(fields) {
            Promise.all(
                fields.map((n) => {
                    this.tempUserPreferences[n.key] = n.value;
                })
            );
        },

        setMyCrewedVesselsLoaded(val: boolean) {
            this.crewedVesselsLoaded = val;
        },

        async setCurrentCrewedVessels() {
            if (!this.crewedVesselsLoaded) {
                // console.log('loading crewed vessels');
                setTimeout(() => {
                    this.crewedVesselsLoaded = true;
                }, 500);
                this.myCrewedVessels = [];
                this.myCrewedVesselIDs = [];
                // console.log(this.myCrewedVessels);
                //
                setTimeout(async () => {
                    let self = this;
                    let levelOneVessels = this.userData.vesselsThatICrew ?? [];
                    let levelTwoVessels = this.userData.vesselsThatImLevelTwo ?? [];
                    let levelThreeVessels = this.userData.vesselsThatIMaster ?? [];
                    // console.log(levelOneVessels, levelTwoVessels, levelThreeVessels);

                    await Promise.all(
                        levelThreeVessels.map(async (id) => {
                            if (!self.myCrewedVesselIDs.includes(id)) {
                                const docSnap = await getDoc(doc(getFirestore(), 'vessels', id));
                                if (docSnap.exists()) {
                                    self.myCrewedVesselIDs.push(id);
                                    self.myCrewedVessels.push({
                                        vesselName: docSnap.data().vesselName,
                                        vesselID: id,
                                        avatarUrl: docSnap.data().avatarUrl ?? '',
                                        role: docSnap.data().vesselRoles ? docSnap.data().vesselRoles[2] : 'Crew',
                                        alertsFlagged: docSnap.data().alertsFlagged ?? false
                                    });
                                }
                            }
                        })
                    );
                    await Promise.all(
                        levelTwoVessels.map(async (id) => {
                            if (!self.myCrewedVesselIDs.includes(id)) {
                                const docSnap = await getDoc(doc(getFirestore(), 'vessels', id));
                                if (docSnap.exists()) {
                                    self.myCrewedVesselIDs.push(id);
                                    self.myCrewedVessels.push({
                                        vesselName: docSnap.data().vesselName,
                                        vesselID: id,
                                        avatarUrl: docSnap.data().avatarUrl ?? '',
                                        role: docSnap.data().vesselRoles ? docSnap.data().vesselRoles[1] : 'Crew',
                                        alertsFlagged: docSnap.data().alertsFlagged ?? false
                                    });
                                }
                            }
                        })
                    );
                    await Promise.all(
                        levelOneVessels.map(async (id) => {
                            if (!self.myCrewedVesselIDs.includes(id)) {
                                const docSnap = await getDoc(doc(getFirestore(), 'vessels', id));
                                if (docSnap.exists()) {
                                    self.myCrewedVesselIDs.push(id);
                                    self.myCrewedVessels.push({
                                        vesselName: docSnap.data().vesselName,
                                        vesselID: id,
                                        avatarUrl: docSnap.data().avatarUrl ?? '',
                                        role: docSnap.data().vesselRoles ? docSnap.data().vesselRoles[0] : 'Crew',
                                        alertsFlagged: docSnap.data().alertsFlagged ?? false
                                    });
                                }
                            }
                        })
                    );
                }, 2000);
            }
        }
    }
});
